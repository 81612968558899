<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="col-4 mb-5 mt-2 text-center custom-offset-md-4">
                    <Dropdown v-model="form.class_id" :options="selectedClass" @change="selectAddAppNumFetch" optionLabel="name" placeholder="Select a Class" class="full-width" :filter="true" style="width: 100%">
                        <template v-slot:option="{ option }">
                            <div>
                                <i v-if="selectedClass === option" class="pi pi-check" style="float: right"></i>
                                {{ option.name }}
                            </div>
                        </template>
                    </Dropdown>
                </div>
                <div class="grid">
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-8 picklist">
                        <PickList v-model="allSubjects" listStyle="height:342px" dataKey="id" breakpoint="1400px" :source="allSubjectsSource" :target="selectedSubjects" >
                            <template #sourceheader>
                                All Subjects
                            </template>
                            <template #targetheader> Selected Subject (for Paper-I)</template>
                            <template #item="slotProps">
                                <div class="flex flex-wrap p-2 align-items-center gap-3">
                                     <div class="flex-1 flex flex-column gap-2">
                                        <span class="font-bold">{{ slotProps.item.subject_title }}</span>
                                        <div class="flex align-items-center gap-2">
                                            <span>{{ slotProps.item.subject_code }}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </template>
                        </PickList>
                    </div>
                    <div class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4">
                        <div class="card paper-card ">
                            <span class="mb-2 pl-3" style="font-weight: 500;">Select (for Paper-II)</span>
                            <hr style="width: 100%;">

                            <!-- <Listbox v-model="selectedPapers2" :options="paper_2" multiple optionLabel="subject_title" class="w-full custom-listbox pl-3"/> -->
                         
                            <div v-for="option in paper_2" :key="option.id" class="flex items-center custom-listbox">
                                <Checkbox type="checkbox" v-model="selectedPapers2" :value="option" :id="option.id" class="mr-3 subject-checkbox" />
                                <label :for="option.id">{{ option.subject_title }}</label>
                            </div>
                            
                            <!-- <Listbox v-model="selectedPapers2" :options="paper_2" multiple optionLabel="subject_title" class="w-full custom-listbox pl-3">
                                <template #option="{ option, selected, select }">
                                <li :class="{ 'bg-blue-500 text-white': selected }" class="cursor-pointer">
                                    <input type="checkbox" :checked="selected" @click="select" class="mr-2" style="z-index: 9999; position: relative;" />
                                    {{ option.subject_title }}
                                </li>
                                </template>
                            </Listbox> -->
                        </div>
                    </div>
                    <div class="flex justify-content-end" style="width: 100%;">
                        <Button label="Save" class="mt-4" @click="save_selected_subjects()" :disabled="isSubmitDisabled" />
                    </div>
                </div>

            </div>
        </div>

        <Toast />
    </div>

</template>

<style>
.picklist .p-picklist-source-controls, .p-picklist-target-controls{
    display: none;
}
.custom-offset-md-4 {
    margin-left: 33.3333%;
}
.paper-card .p-listbox {
  border: none !important;
  box-shadow: none !important;
}
.paper-card{
  border: 1px solid #e0e0e0;
  box-shadow: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 375px;
  overflow-y: scroll;
}
.custom-listbox {
padding: 0.75rem 0.75rem;
  font-weight: 600;
}
.p-listbox-list-wrapper {
    height: 295px;
    overflow-y: scroll;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    border-radius: 3px;
}
</style>

<script>
import Checkbox from 'primevue/checkbox';
export default {
    data() {
        return {
            selectedClass: null,
            form: new this.Form({
                class_id: '',
            }),
            selectedSubject: null,
            selectedClassId: null,
            allSubjects: null,
            searchAble: null,
            paper_2: null,
            selectedPapers2: null,
           
            classOptions: [{ name: 'MBBS Final Year' }, { name: 'MBBS 3rd Year' }, { name: 'MBBS 2nd Year' }, { name: 'BDS Final Year' }, { name: 'BDS 2nd Year' }, { name: 'BDS 1st Year' }],
        };
    },
    components: {},
    created() {},
    mounted() {
        this.getAllClasses();
    },
    methods: {

        selectAddAppNumFetch() {
            this.isDisabled = false;
            this.getRecords(this.form.class_id);
        },
        getAllClasses() {
            var Vthis = this;
            // this.loading = true;
            this.axios
                .post('api/get-class')
                .then((response) => {
                    this.selectedClass = response.data;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        getRecords() {
            var Vthis = this;
            this.loading = true;
            this.selectedClassId = this.form.class_id;
            this.axios
                .post('api/get-class-subjects', { class_id: this.form.class_id })
                .then((response) => {
                    this.allSubjects = [response.data.all_subjects, response.data.selected_subjects ? response.data.selected_subjects : []];
                    this.paper_2 = response.data.selected_subjects;
                    this.selectedPapers2 = response.data.paper2_lists;
                    this.loading = false;
                })
                .catch((error) => {
                    let message = Vthis.customError(error.response);
                    Vthis.showMessage(message, 'error');
                })
                .finally(() => {});
        },
        save_selected_subjects() {
           
            var Vthis = this;
           // this.loading = true;
            this.axios
                .post('api/store-class-subjects', { subject_lists: this.allSubjects,class_id: this.form.class_id, paper2_lists: this.selectedPapers2,})
                .then((response) => {
                  
                    Vthis.showMessage('Saved successfully','success',); 
                })
                .catch((error) => {
                    let message = Vthis.customError(Vthis.form.errors.has('error') ? Vthis.form.errors.get('error') : error  ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => {});
        },
        filter_data_subjects() {
            if (this.searchAble) {
                var value = this.searchAble.toLowerCase();
                var sourceListItems = document.querySelectorAll('.p-picklist-source li');
                sourceListItems.forEach(function (item) {
                    var text = item.textContent.toLowerCase();
                    if (text.indexOf(value) > -1) {
                        item.style.display = 'block';
                    } else {
                        item.style.display = 'none';
                    }
                });
            }
        },
    },
    computed: {
        isSubmitDisabled() {
            return !this.allSubjects || this.allSubjects.length === 0;
        },
    },

    watch: {

        allSubjects(newValue, oldValue) {
          this.paper_2 = this.allSubjects[1];
      // This function will be called when variable1 changes
      // You can perform actions here based on the changes
      //console.log(`variable1 changed from ${oldValue} to ${newValue}`);
    },
    
    },
};
</script>
